<!--
 * @Author: ken yang
 * @Date: 2023-04-06 09:33:01
 * @LastEditTime: 2023-09-20 09:22:46
 * @LastEditors: ken yang
 * @Description:
 * @FilePath: /uexch_pc/src/components/table/BasicTable.vue
-->
<template>
  <el-table
    ref="table"
    :data="data"
    :max-height="maxHeight"
    :class="className"
    :height="height"
    :cell-class-name="cellClass"
    :header-cell-class-name="headerCellClass"
    :header-row-class-name="headerClass"
    :row-class-name="rowClassName"
    :row-key="rowKeyFn"
    :expand-row-keys="expandRowKeys"
    @sort-change="sortChange"
    @expand-change="expandChange"
    @row-click="rowClick"
  >
    <el-table-column v-if="isSerial" type="index" width="50" />
    <el-table-column
      v-for="(item) in config"
      :key="item.prop"
      :label="item.label"
      :prop="item.prop"
      :sortable="item.sortable??false"
      :min-width="item.minWidth"
      :width="item.width"
      :align="item.align??'left'"
      :header-align="item.align??'left'"
      :class-name="item.rowClass"
      :labelClassName="item.rowLabelClass"
    >

      <template #header="{ column, $index }">
        <slot v-if="Object.keys(slots).includes(item.prop+'Header')" :name="item.prop+'Header'" v-bind="{column,$index,table}" />
        <template v-else>{{ item.label }}</template>
      </template>

      <template #default="{ row,$index }">
        <!-- 插槽 -->
        <slot v-if="Object.keys(slots).includes(item.prop)" :class="addClass(item, row)" :name="item.prop" v-bind="{row,$index,table} || {}" />

        <!-- 时间 -->
        <span v-else-if="item.type==='time'" class="num-f" :class="addClass(item, row)">{{ dayjs(row[item.prop]).format('YYYY-MM-DD HH:mm:ss') }}</span>

        <!-- AmountNum 额度 单位划分-->
        <AmountNum v-else-if="item.type==='amountNum'" :class="addClass(item, row)">{{ row[item.prop] }}</AmountNum>

        <!-- Num 逗号 -->
        <Num v-else-if="item.type==='num'" :class="addClass(item, row)">{{ row[item.prop] }}</Num>

        <!-- 可隐藏数字 -->
        <HideNum v-else-if="item.type==='hideNum'" :class="addClass(item, row)">
          <Num v-if="!isEmpty(item.value) && row">{{ item.value(row) }}</Num>
          <Num v-else-if="!isEmpty(row[item.prop]) ">{{ row[item.prop] }}</Num>
        </HideNum>

        <!-- 字典选择 -->
        <span v-else-if="!isEmpty(item.options)" :class="addClass(item, row)">
          {{ (item?.options?.find((obj)=>obj.value===row[item.prop]))?.label }}
        </span>

        <!-- 直接赋值 -->
        <span v-else-if="!isEmpty(item.value)" :class="addClass(item, row)">{{ item.value(row) }}</span>

        <!-- 图片 -->
        <span v-else-if="item.type==='img'">
          <img v-viewer :class="addClass(item) ?? 'w-60 h-60'" :src="row[item.prop]">
        </span>

        <!-- 普通文本 -->
        <span v-else :class="addClass(item, row)">{{ row[item.prop] }}</span>
      </template>

    </el-table-column>
    <el-table-column
      v-if="Object.keys(slots).includes('action')"
      header-align="right"
      align="right"
      :width="actionWidth"
      :label="$t('table.operation')"
      fixed="right"
    >
      <template #default="{ row,$index }">
        <slot name="action" v-bind="{ row,$index,table }" />
      </template>
    </el-table-column>

    <el-table-column
      v-if="Object.keys(slots).includes('expand')"
      v-slot="{ row,$index }"
      type="expand"
      :width="expandWdith"
      class-name="o-hidden"
    >
      <slot name="expand" v-bind="{ row, $index ,table }" />
    </el-table-column>

    <template #empty>
      <ListEmpty />
    </template>
  </el-table>
</template>
<script setup>
import Sortable from 'sortablejs'
const emit = defineEmits()
const table = $ref(null)
let expandWdith = $ref(null)
nextTick(() => {
  expandWdith = 0
})

const slots = useSlots()
const props = defineProps({
  config: {},
  data: {},
  maxHeight: {},
  isDraggable: {},
  onStart: {},
  onEnd: {},
  cellClass: {},
  headerClass: {},
  headerCellClass: {},
  isSerial: {
    default: false
  },
  rowClassName: {},
  rowKeyFn: {},
  expandRowKeys: {},
  actionWidth: {
    default: 80,
  },
  className: {},
  height: {}
})

const _this = $ref({})

const init = () => {
  const el = document.querySelector('.el-table__body-wrapper tbody')

  // 2. 创建并配置相关参数
  var sortable = new Sortable(el, {

    animation: 150,
    onStart (/** Event*/evt) {
      emit('onStart', evt)
    },
    onEnd (evt) {
      emit('onEnd', evt)
    }

  })
}

const addClass = (item, row) => {
  if (!item.class) {
    return ''
  }
  return isFunc(item.class) ? item.class(row) : item.class
}

const expandChange = (row) => {
  emit('expandChange', row)
}

const sortChange = ({ column, prop, order }) => {
  emit('onSort', { column, prop, order })
}
const rowClick = (row, cloumn) => {
  emit('row-click', row, cloumn)
}

onMounted(() => {
  if (props.isDraggable) {
    init()
  }
})
</script>

<style lang="scss" scoped>
</style>
