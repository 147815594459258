/*
 * @Author: ken yang
 * @Date: 2023-05-08 17:24:27
 * @LastEditTime: 2023-07-24 17:32:08
 * @LastEditors: ken yang
 * @Description:
 * @FilePath: /uexch_pc/src/utils/globalFunc/timer.js
 */
const runTimer = (b, t = 1000) => {
  setTimeout(() => {
    const n = b()
    if (n) {
      runTimer(b, t)
    }
  }, t)
}

export { runTimer }

