import AppLayout from '@/layout/AppLayout.vue'

// import Quotes from '@/view/quotes/Quotes.vue'
const Quotes = () => import('@/view/quotes/Quotes.vue')

const routes = [
  {
    component: AppLayout,
    children: [
      {
        path: '/quotes',
        component: Quotes,
        meta: { key: 'quotes' },
      },
    ]
  },
]
const components = {}

export default {
  routes,
  components,
}
