/*
 * @Author: ken yang
 * @Date: 2022-10-08 14:06:46
 * @LastEditTime: 2023-05-17 15:52:20
 * @LastEditors: ken yang
 * @Description: 钱包接口
 * @FilePath: /uexch_pc/src/api/wallet.js
 */
import axios from '@/utils/request'

// 查询划转法币币种信息
const transferOtcCoinUrl = '/account/query/transfer/otc/coin'
const transferOtcCoin = async (params) => {
  const { data } = await axios.post(transferOtcCoinUrl, params)
  return data
}

// 划转
const transferUrl = '/account/transfer'
const transfer = async (params) => {
  const { data } = await axios.post(transferUrl, params)
  return data
}

// 划转futures
const transferFuturesUrl = '/account/transfer'
const transferFutures = async (params) => {
  const { data } = await axios.post(transferFuturesUrl, params)
  return data
}

// 提币币种列表
const withdrawCoinUrl = '/account/query/withdraw/coin'
const withdrawCoin = async (body = {}) => {
  const { data } = await axios.post(withdrawCoinUrl, body)
  return data.data
}

const withdrawCheckUrl = '/account/withdraw-check'
const withdrawCheck = async (body = {}) => {
  const { data } = await axios.post(withdrawCheckUrl, body)
  return data.data
}

//
const withdrawUrl = '/account/withdraw'
const withdraw = async (body = {}) => {
  const { data } = await axios.post(withdrawUrl, body)
  return data.data
}

// 充值币种列表
const depositCoinUrl = '/account/query/deposit/coin'
const depositCoin = async (body = {}) => {
  const { data } = await axios.post(depositCoinUrl, body)
  return data.data
}

// 总资产估值
const querySumUrl = '/account/query/sum'
const querySum = async (body) => {
  const { data } = await axios.post(querySumUrl, body || {})
  return data.data
}

// 现货和法币资产估值
const queryInfoUrl = '/account/query/info'
const queryInfo = async (body) => {
  const { data } = await axios.post(queryInfoUrl, body || {})
  return data.data
}

// 查询充提币记录
const queryDepositAndWithdrawListUrl = '/account/query/queryDepositAndWithdrawList'
const queryDepositAndWithdrawList = async (body) => {
  const { data } = await axios.post(queryDepositAndWithdrawListUrl, body || {})
  return data.data
}

// 划转记录
const userTransferPageUrl = '/account/user-transfer-page'
const userTransferPage = async (body) => {
  const { data } = await axios.post(userTransferPageUrl, body || {})
  return data.data
}

// 查询用户划转记录
const queryTransferListUrl = '/account/query/queryTransferList'
const queryTransferList = async (body) => {
  const { data } = await axios.post(queryTransferListUrl, body || {})
  return data.data
}

// 查询交易记录 - 优化 （其他）
const otherRecordUrl = '/account/query/other-record'
const otherRecord = async (body) => {
  const { data } = await axios.post(otherRecordUrl, body || {})
  return data.data
}

// 用户提币配置
const userWithdrawConfigUrl = '/account/user-withdraw-config'
const userWithdrawConfig = async (body) => {
  const { data } = await axios.post(userWithdrawConfigUrl, body || {})
  return data.data
}

const profitAndLossUrl = '/account/query/profitAndLoss'
const profitAndLoss = async (body) => {
  const { data } = await axios.post(profitAndLossUrl, body || {})
  return data.data
}

const accountChangeUrl = '/account/query/accountChange'
const accountChange = async (body) => {
  const { data } = await axios.post(accountChangeUrl, body || {})
  return data.data
}

const dailyIncomeUrl = '/account/query/dailyIncome'
const dailyIncome = async (body) => {
  const { data } = await axios.post(dailyIncomeUrl, body || {})
  return data.data
}

const addWithdrawAddressUrl = '/account/add/withdrawAddress'
const addWithdrawAddress = async (body) => {
  const { data } = await axios.post(addWithdrawAddressUrl, body || {})
  return data.data
}

const editWithdrawAddressUrl = '/account/edit/withdrawAddress'
const editWithdrawAddress = async (body) => {
  const { data } = await axios.post(editWithdrawAddressUrl, body || {})
  return data.data
}

const delWithdrawAddressUrl = '/account/delete/withdrawAddress'
const delWithdrawAddress = async (body) => {
  const { data } = await axios.post(delWithdrawAddressUrl, body || {})
  return data.data
}

const getWithdrawAddressUrl = '/account/get/withdrawAddress'
const getWithdrawAddress = async (body) => {
  const { data } = await axios.post(getWithdrawAddressUrl, body || {})
  return data.data
}

const withdrawByUidUrl = '/account/withdraw/withdrawByUid'
const withdrawByUid = async (body) => {
  const { data } = await axios.post(withdrawByUidUrl, body || {})
  return data
}

const getUserInfoUrl = '/user/user-info'
const getUserInfo = async (body) => {
  const { data } = await axios.post(getUserInfoUrl, body || {})
  return data.data
}

const querySimpleUrl = '/account/coin/query/simple'
const querySimple = async (body) => {
  const { data } = await axios.post(querySimpleUrl, body || {})
  return data.data
}

const getAddressByDomainUrl = '/account/getAddressByDomain'
const getAddressByDomain = async (body) => {
  const { data } = await axios.post(getAddressByDomainUrl, body || {})
  return data.data
}

// const verificationAddressUrl = '/account/withdraw/verificationAddress'
// const verificationAddress = async (body) => {
//   const { data } = await axios.post(verificationAddressUrl, body || {})
//   return data.data
// }

const getRateByCoinNameUrl = '/account/rate/getRateByCoinName'
const getRateByCoinName = async (body) => {
  const { data } = await axios.post(getRateByCoinNameUrl, body || {})
  return data.data
}

const needLoginUrl = [transferOtcCoinUrl]

export default {
  needLoginUrl,
  transferOtcCoin,
  transfer,
  transferFutures,
  withdrawCoin,
  withdrawCheck,
  withdraw,
  depositCoin,
  querySum,
  queryInfo,
  queryDepositAndWithdrawList,
  userTransferPage,
  queryTransferList,
  otherRecord,
  userWithdrawConfig,
  profitAndLoss,
  accountChange,
  dailyIncome,
  addWithdrawAddress,
  editWithdrawAddress,
  delWithdrawAddress,
  getWithdrawAddress,
  withdrawByUid,
  getUserInfo,
  // verificationAddress,
  querySimple,
  getRateByCoinName,
  getAddressByDomain
}
