/*
 * @Author: ken yang
 * @Date: 2023-05-31 17:36:33
 * @LastEditTime: 2023-05-31 17:38:34
 * @LastEditors: ken yang
 * @Description:
 * @FilePath: /uexch_pc/src/utils/globalFunc/borwserLang.js
 */
const lang = () => {
  const rowLang = navigator.language || navigator.userLanguage
  if (rowLang.includes('CN')) return LANGCN
  if (rowLang.includes('TW')) return LANGTW
  if (rowLang.includes('en')) return LANGEN
}

export default lang
