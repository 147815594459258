<!--
 * @Author: ken yang
 * @Date: 2023-02-01 14:17:09
 * @LastEditTime: 2023-02-01 15:04:53
 * @LastEditors: ken yang
 * @Description:
 * @FilePath: /uexch_pc/src/view/userCenter/SecurityCenter/components/DiaChangeName.vue
-->
<template>
  <el-drawer
    class="DiaTradeStyleSet dialog-d w-480"
    :modelValue="modelValue"
    :destroy-on-close="true"
    append-to-body
    @close="onClose"
  >
    <template #header>
      <Column class="w-100b" x="s">
        <Row class="font-22 bold c-d" x="c">{{ $t("trade.preference") }}</Row>
      </Column>
    </template>

    <Column v-if="['/futures'].includes(route.path)" class="w-100b m-t-2 p-b-28 m-b-28 border-b">
      <Row class="m-b-18 label c-info">{{ $t("trade.tradeSet") }}</Row>
      <Row class="w-100b" x="sb" y="c">
        <Row class="m-b-10 label">{{ $t('futures.positionMode') }}</Row>
        <Row class=" label c-info pointer" y="c" @click="() => dialogShow = true">
          <span>{{ futuresStore.positionModeOptions.find(e => futuresStore.positionMode == e.value)?.label }}</span>
          <IconSvg class="label-sm m-l-6 c-placeholder" name="icon-IconMore_Home" />
        </Row>
      </Row>
    </Column>
    <Column class="w-100b ">
      <Row class="m-b-18 label c-info">{{ $t("trade.basic") }}</Row>
      <Row class="m-b-10 label">{{ $t("trade.kLineSettings") }}</Row>
      <el-radio-group :modelValue="configStore.tradeStyle" class="w-100b m-b-10 row f-x-sb f-wrap">
        <Row
          v-for="(s) in configStore.tradeStyleMap"
          :key="s"
          class="w-200 h-48 p-l-20 p-r-10 bg-line radius-4 pointer transition radio border"
          :class="[configStore.tradeStyle == s ? 'border-c-primary' : 'border-c-line']"
          y="c"
          x="sb"
          @click="setTradeStyle(s)"
        >
          <Row>
            <img v-if="s == 'trade-style-0'" class="w-21 h-18" src="@/image/trade/trade-style-0.webp">
            <img v-if="s == 'trade-style-1'" class="w-21 h-18" src="@/image/trade/trade-style-1.webp">
            <span class="lh-18 label w-80 m-l-8">{{ $t(`trade.kLineSettingsMap.${s}`) }}</span>
          </Row>
          <el-radio :label="s" size="large">{{ '' }}</el-radio>
        </Row>
      </el-radio-group>
    </Column>
    <el-dialog
      v-model="dialogShow"
      class="DiaTradeStyleSet dialog-d"
      modal-class="dialog-m-center"
      :destroy-on-close="true"
      :close-on-click-modal="false"
      append-to-body
      width="500px"
    >
      <template #header>
        <Column class="w-100b" x="s">
          <Row class="font-22 bold m-b-2" x="c">{{ $t('futures.positionMode') }}</Row>
        </Column>
      </template>

      <Column>
        <Row
          class="transition w-100b p-tb-14 radius-4 p-lr-14 bg-line border pointer m-b-16"
          :class="positionMode == futuresStore.positionModeMap.hedge ? 'border-c-primary' : 'border-c-line'"
          x="s"
          @click="positionModeChange(futuresStore.positionModeMap.hedge)"
        >
          <el-radio class="m-r-0" :label="futuresStore.positionModeMap.hedge" size="large" :modelValue="positionMode">{{ '' }}</el-radio>
          <Column class="" y="c">
            <span class="label m-b-2">{{ $t(`futures.positionModeMap.hedge`) }}</span>
            <span class="c-info label-sm lh-18">{{ $t(`futures.positionModeNotesMap.hedge`) }}</span>
          </Column>
        </Row>
        <Row
          class="transition w-100b p-tb-14 radius-4 p-lr-14 bg-line border pointer m-b-18"
          :class="positionMode == futuresStore.positionModeMap.oneWay ? 'border-c-primary' : 'border-c-line'"
          x="s"
          @click="positionModeChange(futuresStore.positionModeMap.oneWay)"
        >
          <el-radio class="m-r-0" :label="futuresStore.positionModeMap.oneWay" size="large" :modelValue="positionMode">{{ '' }}</el-radio>
          <Column class="" y="c">
            <span class="label m-b-2">{{ $t(`futures.positionModeMap.oneWay`) }}</span>
            <span class="c-info label-sm lh-18">{{ $t(`futures.positionModeNotesMap.oneWay`) }}</span>
          </Column>
        </Row>
        <span class="c-info label-sm lh-18">{{ $t('futures.positionModeNotes') }}</span>
        <Row x="e" class="w-100b m-t-34">
          <el-button class="w-min-100 title-sm h-52 font-w-400 m-r-10" :loading="setFuturesSettingsLoading" link @click="() => dialogShow = false">{{ $t('alert.cancel') }}</el-button>
          <el-button class="w-min-100 title-sm h-52 font-w-400" :loading="setFuturesSettingsLoading" type="primary" @click="setFuturesSettings">{{ $t('alert.confirm') }}</el-button>
        </Row>
      </Column>
    </el-dialog>
  </el-drawer>
</template>
<script setup>
const route = useRoute()
const c2cStore = useC2cStore()
const userStore = useUserStore()
const emit = defineEmits()
const props = defineProps({
  modelValue: {
    default: false
  },
})
const configStore = useConfigStore()
const futuresStore = useFuturesStore()

let dialogShow = $ref(false)
let setFuturesSettingsLoading = $ref(false)
const onClose = () => {
  emit('update:modelValue', false)
}

let positionMode = $ref(futuresStore.positionMode ?? futuresStore.positionModeMap.hedge)

const positionModeChange = async (v) => {
  positionMode = v
}

const setFuturesSettings = async () => {
  setFuturesSettingsLoading = true
  try {
    await futuresStore.setFuturesSettings(positionMode)
    ElMessage.success($t('alert.successfulSetup'))
    dialogShow = false
  } catch (error) {
  } finally {
    setFuturesSettingsLoading = false
  }
}

const setTradeStyle = (s) => {
  configStore.setTradeStyle(s)
}

watch(() => dialogShow, (show) => {
  if (show && ['/futures'].includes(route.path)) {
    positionMode = futuresStore.positionMode ?? futuresStore.positionModeMap.hedge
  }
})

watch(() => props.modelValue, (show) => {
  if (show && ['/futures'].includes(route.path)) {
    futuresStore.getFuturesSettings()
  }
})

</script>
<style lang="scss">
.DiaTradeStyleSet{
  .el-radio__inner{
    width: 16px !important;
    height: 16px !important;
    &::after{
      width: 5px !important;
      height: 5px !important;
    }
  }
  .el-drawer__header{
    padding: 35px 30px !important;
    margin-bottom: 15px !important;
  }
  .el-drawer__body{
    padding: 0 30px !important;
  }
  .el-dialog__headerbtn{
    top: 0 !important;
    right: 0 !important;
  }
}
</style>
