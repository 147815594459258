/*
 * @Author: ken yang
 * @Date: 2023-04-18 17:56:00
 * @LastEditTime: 2023-07-09 15:55:02
 * @LastEditors: ken yang
 * @Description:
 * @FilePath: /uexch_pc/src/utils/globalFunc/GlobalError.js
 */
const GlobalError = {
  // install: (app, options) => {
  //   app.config.errorHandler = (err, vm, info) => {
  //     console.log('捕获vue内部异常', err, vm, info)
  //   }
  //   window.addEventListener('error', (error) => {
  //   }, true)
  //   window.addEventListener('unhandledrejection', (event) => {
  //     console.log('全局捕获未处理的Promise异常', event)
  //   })
  // }
}

export default GlobalError
