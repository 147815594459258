<template>
  <div class="AppFooter">
    <Row class="w-1200 h-100b" x="sb">
      <Column class="h-100b m-r-60 f-size-170">
        <img src="@/image/home/logo-footer.webp" class="w-158 h-42">
        <span class="m-t-10 text-left">@ 2022 bifinance.com</span>
      </Column>
      <Row class=" h-100b w-1140">
        <Column class="link-box f-1 w-200">
          <div class="title">{{ $t('footer.about') }}</div>
          <span class="link h-22" @click="routerTo('/help/aboutUs')">{{ $t('footer.aboutUs') }}</span>
          <span v-to="'/bft'" class="link h-22">{{ $t('bft.aboutBFT') }}</span>
          <span class="link h-22" @click="toLink">{{ $t('footer.bulletin') }}</span>
          <span class="link h-22" @click="brandJump">{{ $t('footer.brandDy') }}</span>
        </Column>
        <Column class="link-box f-1">
          <div class="title">{{ $t('footer.service') }}</div>
          <span class="link h-22" @click="routerTo('/help/content?type=userAgreement')">{{ $t('footer.userAgreement') }}</span>
          <span class="link h-22" @click="routerTo('/help/fees')">{{ $t('footer.fees') }}</span>
          <span class="link " @click="toLink">{{ $t('footer.helpCenter') }}</span>
          <span class="link h-22" @click="routerTo('/channelVerify')">{{ $t('footer.channelVerify') }}</span>
        </Column>
        <Column class="link-box  w-200">
          <div class="title">{{ $t('footer.support') }}</div>
          <div class="link h-22" @click="routerTo('/help/content?type=privacyPolicy')">{{ $t('footer.privacyPolicy') }}</div>
          <div class="link h-22" @click="routerTo('/help/content?type=responsibility')">{{ $t('footer.responsibility') }}</div>
          <div class="link h-22" @click="routerTo('/help/content?type=kyc')">{{ $t('footer.kyc') }}</div>
        </Column>
        <Column class="link-box w-350">
          <div class="title">{{ $t('footer.contactUs') }}</div>
          <div class="af-email h-23">{{ $t('footer.contactUs') }}：service@BiFinance.com</div>
          <div class="m-b-17 h-23">{{ $t('footer.applyToList') }}：listing@BiFinance.com</div>
          <Row class="apps w-100b">
            <IconSvg name="icon-IconTelegram_Footer" class="image c-hover-white" @click="open('https://t.me/BiFinance_Global')" />
            <IconSvg name="icon-IconTelegram_Footer" class="image c-hover-white" @click="open('https://t.me/BiFinance_Globalcn')" />
            <IconSvg name="icon-IconFacebook_Footer" class="image c-hover-white" @click="open('https://www.facebook.com/BiFinanceExchange')" />
            <IconSvg name="icon-IconTwitter_Footer" class="image c-hover-white" @click="open('https://twitter.com/BiFinanceE')" />
            <IconSvg name="icon-IconMedium_Footer" class="image c-hover-white" @click="open('https://medium.com/@bifinance05')" />
          </Row>
        </Column>
      </Row>
    </Row>
  </div>
</template>

<script setup>
const userStore = useUserStore()
const configStore = useConfigStore()
const route = useRoute()
const props = defineProps({
  price: {},
  amount: {},
  total: {},
  percent: {},
  type: {}
})

const open = (url) => {
  if (!/^http*/.test(url)) {
    url = 'http://' + url
  }
  window.open(url, '_blank')
}

const toLink = () => {
  const localeMap = {
    [LANGEN]: 'en-001',
    [LANGCN]: 'zh-cn'
  }
  const url = `https://bifinance.zendesk.com/hc/${localeMap[configStore.locale]}`
  window.open(url)
}

const brandJump = () => {
  const localeMap = {
    [LANGEN]: 'en-001',
    [LANGCN]: 'zh-cn'
  }

  const localePath = {
    [LANGEN]: '/sections/7124911573135-Brand-News',
    [LANGCN]: '/sections/7124911573135-品牌动态'
  }
  const url = `https://bifinance.zendesk.com/hc/${localeMap[configStore.locale]}${localePath[configStore.locale]}`
  window.open(url)
}

const routerTo = (path) => {
  if (route.query.winId) {
    winMes.postParent('footerRouteTo', path)
  } else {
    router.push(path)
  }
}

onBeforeMount(() => {})
</script>

<style lang="scss">
@use 'sass:math';
@use 'sass:map';
@import '@/sass/var/index.scss';
.AppFooter {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 360px;
  padding: 108px 0 104px;
  box-sizing: border-box;
  background-color: #16151a;
  color: rgba(255, 255, 255, 0.5);
  width: 100%;
  .language {
    width: 146px;
    height: 44px;
    padding-left: 16px;
    box-sizing: border-box;
    border: 1px solid #fff;
    border-radius: 4px;
    box-sizing: border-box;
    .icon {
      transition: all 0.3s;
    }
    &:hover {
      .icon {
        transform: rotateZ(-90deg);
      }
    }
  }
  .link-box {
    height: 100%;
    font-size: 16px;
    color: rgba(255, 255, 255, 0.5);
    .title {
      font-size: 20px;
      color: #fff;
      margin-bottom: 20px;
      font-weight: 400;
    }
    .link {
      font-size: 16px;
      color: rgba(255, 255, 255, 0.5);
      margin-bottom: 14px;
      cursor: pointer;
    }
    .af-email{
      font-size: 16px;
      color: rgba(255, 255, 255, 0.5);
      margin-bottom: 14px;
    }
    .apps {
      display: flex;
      .image {
        width: 24px;
        height: 24px;
        margin-right: 8px;
        color: rgba(255, 255, 255, 0.5);
        cursor: pointer;
      }
    }
  }
}
</style>
