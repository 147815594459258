/*
 * @Author: ken yang
 * @Date: 2022-09-07 09:21:36
 * @LastEditTime: 2023-09-14 11:35:30
 * @LastEditors: ken yang
 * @Description: map的store
 * @FilePath: /uexch_pc/src/store/mail.js
 */
import { defineStore } from 'pinia'

const subKey = WS_KEYS.mail
const useMailStore = defineStore('mailStore', {
  state: () => {
    return {
      expandId: null,
      msgType: '',
      _switch: {},
      list: null,
      _unreadCount: {
        allMessages: 0,
        msgPlatform: 0,
        msgTrade: 0,
        msgFinance: 0,
        msgSys: 0,
      },
      _typeValues: {
        allMessages: '',
        msgPlatform: 1,
        msgTrade: 3,
        msgFinance: 2,
        msgSys: 4,
      }
    }
  },
  getters: {
    switch: (state) => {
      return state._switch
    },
    typeValues: (state) => {
      return state._typeValues
    },
    unreadCount: (state) => {
      return state._unreadCount
    },
  },
  actions: {
    async getSetInfo () {
      const data = await mailApi.getSetInfo()
      this.$patch(state => {
        state._switch = data
      })
    },
    async getUnreadCount () {
      const data = await mailApi.getUnreadCount()
      this.$patch(state => {
        state._unreadCount = {
          allMessages: data.all ?? 0,
          msgPlatform: data.msgPlatform ?? 0,
          msgTrade: data.msgTrade ?? 0,
          msgFinance: data.msgFinance ?? 0,
          msgSys: data.msgSys ?? 0,
        }
      })
    },
    async getList  () {
      const configStore = useConfigStore()
      const body = {
        ...PAGES,
        // readed: 0,
      }

      const data = await mailApi.list(body)
      const ls = data.records.map(e => {
        return {
          ...e,
          title: e.title,
          content: e.content,
          link: e.pcLink,
        }
      })
      this.$patch(state => {
        state.list = ls
      })
    },
    async read  ({ id }) {
      const data = await mailApi.update({ id, readed: 1 })
      this.getList()
    },
    subRecords () {
      const ws = Socket.autoConnect()
      const sendData = {
        'id': randomString(),
        'cmd': 'sub',
        'topic': subKey,
        'data': {}
      }

      ws.on(sendData.topic, ({ data }) => {
        this.getUnreadCount()
        this.getList()
        eventBus.emit(subKey, data)
      })
      ws.send(sendData)
    },
    unsubRecords () {
      const ws = Socket.autoConnect()
      const sendData = {
        'cmd': 'unsub',
        'topic': subKey,
        'data': {}
      }
      eventBus.off(subKey)
      ws.send(sendData)
      ws.off(sendData.topic)
    }
  },
  init () {
    if (localStorage.token) {
      this.getSetInfo()
      this.getUnreadCount()
      this.getList()
      // this.subRecords()
    }
  },
})

export default useMailStore
