/*
 * @Author: ken yang
 * @Date: 2022-09-28 19:44:55
 * @LastEditTime: 2023-01-30 10:49:59
 * @LastEditors: ken yang
 * @Description:
 * @FilePath: /uexch_pc/src/view/activity/index.js
 */
import AppLayout from '@/layout/AppLayout.vue'
const RegisterSendBTC = () => import('@/view/activity/RegisterSendBTC.vue')

const routes = [
  {
    component: AppLayout,
    children: [
      {
        path: '/registerSendBTC',
        component: RegisterSendBTC,
      },
    ],
  },
]
const components = {}
export default {
  routes,
  components,
}

