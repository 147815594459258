/*
 * @Author: ken yang
 * @Date: 2022-09-28 19:44:55
 * @LastEditTime: 2023-05-22 17:41:50
 * @LastEditors: ken yang
 * @Description: 个人中心路由
 * @FilePath: /uexch_pc/src/view/psersonalCenter/index.js
 */
import AppLayout from '@/layout/AppLayout.vue'
const psersonalCenterLayout = () => import('@/view/psersonalCenter/layout/psersonalCenterLayout.vue')
const UserOverview = () => import('@/view/psersonalCenter/userOverview/UserOverview.vue')
const AccountSecurity = () => import('@/view/psersonalCenter/accountSecurity/AccountSecurity.vue')
const Authentication = () => import('@/view/psersonalCenter/authentication/Authentication.vue')
const ModifyPwd = () => import('@/view/psersonalCenter/accountSecurity/components/ModifyPwd.vue')
const ModifyFundsPwd = () => import('@/view/psersonalCenter/accountSecurity/components/ModifyFundsPwd.vue')
const ModifyPhoneOrEmail = () => import('@/view/psersonalCenter/accountSecurity/components/ModifyPhoneOrEmail.vue')
const BindGoogle = () => import('@/view/psersonalCenter/accountSecurity/components/BindGoogle.vue')
const UnBindGoogle = () => import('@/view/psersonalCenter/accountSecurity/components/UnBindGoogle.vue')
const SwitchGoogle = () => import('@/view/psersonalCenter/accountSecurity/components/SwitchGoogle.vue')

const routes = [
  {
    component: AppLayout,
    children: [
      {
        path: '/psersonalCenter',
        component: psersonalCenterLayout,
        children: [
          {
            path: 'userOverview',
            component: UserOverview,
          },
          {
            path: 'accountSecurity',
            component: AccountSecurity,
            name: 'accountSecurity'
          },
          {
            path: 'authentication',
            component: Authentication,
          },
        ]
      },
      {
        path: '/psersonalCenter/modifyPwd',
        component: ModifyPwd,
      },
      {
        path: '/psersonalCenter/modifyFundsPwd',
        component: ModifyFundsPwd,
        name: 'modifyFundsPwd'
      },
      {
        path: '/psersonalCenter/modifyPhoneOrEmail',
        component: ModifyPhoneOrEmail,
      },
      {
        path: '/psersonalCenter/bindGoogle',
        component: BindGoogle,
        name: 'bindGoogle'
      },
      {
        path: '/psersonalCenter/unBindGoogle',
        component: UnBindGoogle,
      },
      {
        path: '/psersonalCenter/switchGoogle',
        component: SwitchGoogle,
      },
    ],
  },
]
const components = {}
export default {
  routes,
  components,
}

