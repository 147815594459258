/*
 * @Author: ken yang
 * @Date: 2022-09-01 09:10:19
 * @LastEditTime: 2023-05-31 17:52:23
 * @LastEditors: ken yang
 * @Description:
 * @FilePath: /uexch_pc/src/main.js
 */
import * as vue from 'vue'
import App from '@/App.vue'
import router from '@/router'
import store, { useUserStore } from '@/store'
import { createLanguage } from '@/language'
import 'default-passive-events'
import * as globalFunc from '@/utils/globalFunc/index.js'
import * as apis from '@/api/index.js'
import directives from '@/utils/directive/index.js'
import GlobalError from '@/utils/globalFunc/GlobalError.js'
import * as constant from '@/utils/constant.js'
import * as urls from '@/utils/url.js'
import NP from 'number-precision'
import './registerServiceWorker'
import duration from 'dayjs/plugin/duration'
import BigNumber from 'bignumber.js'
import 'viewerjs/dist/viewer.css'
import VueViewer from 'v-viewer'
dayjs.extend(duration)
import 'uno.css'

winMes.init()
window.addEventListener('resize', (e) => {
  eventBus.emit('onResize', e)
})

const { winId, platform } = getUrlQuery()

globalThis.winId = winId
globalThis.platform = platform ?? 'h5'
globalThis.$v = $v
globalThis.NP = NP
globalThis.BigNumber = BigNumber
globalThis.envMode = import.meta.env.MODE

let $t = null

const main = async (com, dom, options) => {
  $theme.init()

  const res = await createLanguage()
  if (!$t) {
    $t = res.$t
    globalThis.$t = $t
  }

  setTimeout(() => {
    document.querySelector('html').setAttribute('version', import.meta.env.VITE_APP_VERSION)
  }, 3000)
  const app = import.meta.env.VITE_APP_IS_SSR ? vue.createSSRApp(App) : vue.createApp(com ?? App, options)
  Object.keys(globalFunc).forEach(funcName => {
    app.config.globalProperties[funcName] = globalFunc[funcName]
  })
  Object.keys(constant).forEach(k => {
    app.config.globalProperties[k] = constant[k]
    globalThis[k] = constant[k]
  })
  Object.keys(urls).forEach(k => {
    app.config.globalProperties[k] = urls[k]
    globalThis[k] = urls[k]
  })
  Object.keys(apis).forEach(k => {
    app.config.globalProperties[k] = apis[k]
  })

  app.config.globalProperties.$t = $t
  app.config.globalProperties.$f = $f
  app.config.globalProperties.$v = $v
  app.config.globalProperties.$theme = $theme
  app.config.globalProperties.dayjs = dayjs
  app.config.globalProperties.winMes = winMes
  app.config.globalProperties.NP = NP
  app.config.globalProperties.BigNumber = BigNumber
  app.config.globalProperties.envMode = import.meta.env.MODE

  // app.use(VueViewer)
  app.use(directives)
  app.use(router)
  app.use(store)
  app.use(VueViewer)
  app.use(GlobalError)
  app.mount(dom ?? '#app')
  nextTick(() => {
    if (import.meta.env.DEV) {
      idleInModuleList()
    }
  })
  return { app, router }
}

main()

globalThis.BiCreateApp = async (com, dom, options) => {
  const { app, router } = await main(com, dom, options)
  return { app, router }
}

export const createApp = main
