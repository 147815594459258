/*
 * @Author: ken yang
 * @Date: 2023-06-06 09:21:07
 * @LastEditTime: 2023-09-21 11:13:59
 * @LastEditors: ken yang
 * @Description:
 * @FilePath: /uexch_pc/src/api/futures.js
 */
import axios from '@/utils/request'

const openOrdersUrl = '/contract/user-entrust-list'
const openOrders = async (body) => {
  const { data } = await axios.post(openOrdersUrl, body)
  return data.data
}

const historyUrl = '/contract/entrust-history-pagination'
const history = async (body) => {
  const { data } = await axios.post(historyUrl, body)
  return data.data
}

const historyDetailUrl = '/contract/entrust-detail-page'
const historyDetail = async (body) => {
  const { data } = await axios.post(historyDetailUrl, body)
  return data.data
}

const positionHistoryUrl = '/contract-account/usdt/contract/user/position-history'
const positionHistory = async (body) => {
  const { data } = await axios.post(positionHistoryUrl, body)
  return data.data
}

const tradeHistoryUrl = '/contract/entrust-detail-record-page'
const tradeHistory = async (body) => {
  const { data } = await axios.post(tradeHistoryUrl, body)
  return data.data
}

const transactionHistoryUrl = '/contract-account/usdt/contract/user/contract/bill'
const transactionHistory = async (body) => {
  const { data } = await axios.post(transactionHistoryUrl, body)
  return data.data
}

const markedPriceUrl = '/contract-account/usdt/contract/marked/price'
const markedPrice = async (body) => {
  const { data } = await axios.post(markedPriceUrl, body)
  return data.data
}

const getUserSettingsUrl = '/contract-account/user/contract/get-set'
const getUserSettings = async (body) => {
  const { data } = await axios.post(getUserSettingsUrl, body)
  return data.data
}

const setUserSettingsUrl = '/contract-account/user/contract/update/set'
const setUserSettings = async (body) => {
  const { data } = await axios.post(setUserSettingsUrl, body)
  return data.data
}

const cancelOrderUrl = '/contract/cancel/trust-single'
const cancelOrder = async (body) => {
  const { data } = await axios.post(cancelOrderUrl, body)
  return data.data
}

const cancelOrderAllUrl = '/contract/cancel/trust-whole'
const cancelOrderAll = async (body) => {
  const { data } = await axios.post(cancelOrderAllUrl, body)
  return data.data
}

const contractPositionUrl = '/contract-account/usdt/contract/user/position'
const contractPosition = async (body = {}) => {
  const { data } = await axios.post(contractPositionUrl, body)
  return data.data
}

const contractUsdtlistUrl = '/contract-account/user/contract/usdt'
const contractUsdtlist = async (body = {}) => {
  const { data } = await axios.post(contractUsdtlistUrl, body)
  return data.data
}

const contractUsdtlist2Url = '/contract-account/user/contract/usdt/list'
const contractUsdtlist2 = async (body = {}) => {
  const { data } = await axios.post(contractUsdtlist2Url, body)
  return data.data
}

const contractStatusUrl = '/user/get/open/usdt-contract-status'
const contractStatus = async (body = {}) => {
  const { data } = await axios.post(contractStatusUrl, body)
  return data.data
}

const edditContractStatusUrl = '/user/edit/open/usdt-contract-status'
const edditContractStatus = async (body = {}) => {
  const { data } = await axios.post(edditContractStatusUrl, body)
  return data.data
}

const contractAddOrderUrl = '/contract/add/order'
const contractAddOrder = async (body = {}) => {
  const { data } = await axios.post(contractAddOrderUrl, body)
  return data
}

const contractCoinmarketUrl = '/contract-market/get-contract-coinmarket'
const contractCoinmarket = async (body = {}) => {
  const { data } = await axios.post(contractCoinmarketUrl, body)
  return data.data
}

const leverInfoUrl = '/contract-account/user/contract/usdt/lever-info'
const leverInfo = async (body = {}) => {
  const { data } = await axios.post(leverInfoUrl, body)
  return data.data
}

const rateHistoryUrl = '/contract/rateHistory/page'
const rateHistory = async (body = {}) => {
  const { data } = await axios.post(rateHistoryUrl, body)
  return data.data
}

const updateLeverInfoUrl = '/contract-account/user/contract/usdt/update-lever-info'
const updateLeverInfo = async (body = {}) => {
  const { data } = await axios.post(updateLeverInfoUrl, body)
  return data.data
}

const updateMarginUrl = '/contract-account/usdt/contract/update-margin'
const updateMargin = async (body = {}) => {
  const { data } = await axios.post(updateMarginUrl, body)
  return data.data
}

const feeUrl = '/contract/tradeFee/currentUserTradeFee/detail'
const fee = async (body = {}) => {
  const { data } = await axios.post(feeUrl, body)
  return data.data
}

const curFeeUrl = '/contract/tradeFee/currentUserTradeFee'
const curFee = async (body = {}) => {
  const { data } = await axios.post(curFeeUrl, body)
  return data.data
}

const feeListUrl = '/contract/tradeFee/list'
const feeList = async (body = {}) => {
  const { data } = await axios.post(feeListUrl, body)
  return data.data
}

const currentRateURL = '/contract/rateHistory/currentRate'
const currentRate = async (body = {}) => {
  const { data } = await axios.post(currentRateURL, body)
  return data.data
}

const createOrderUrl = '/strategy/contractEntrustStop/createOrder'
const profitLossCreateOrder = async (body = {}) => {
  const { data } = await axios.post(createOrderUrl, body)
  return data
}

const contractEntrustStopUrl = '/strategy/contractEntrustStop/list'
const contractEntrustStop = async (body = {}) => {
  const { data } = await axios.post(contractEntrustStopUrl, body)
  return data.data
}

const contractEntrustStopPageUrl = '/strategy/contractEntrustStop/page'
const contractEntrustStopPage = async (body = {}) => {
  const { data } = await axios.post(contractEntrustStopPageUrl, body)
  return data.data
}

const contractEntrustStopCancelUrl = '/strategy/contractEntrustStop/cancel'
const contractEntrustStopCancel = async (body = {}) => {
  const { data } = await axios.post(contractEntrustStopCancelUrl, body)
  return data
}

const contractEntrustStopSaveUrl = '/strategy/contractEntrustStop/save'
const contractEntrustStopSave = async (body = {}) => {
  const { data } = await axios.post(contractEntrustStopSaveUrl, body)
  return data
}

const positionDepositUrl = '/contract/get/positionDeposit'
const positionDeposit = async (body = {}) => {
  const { data } = await axios.post(positionDepositUrl, body)
  return data
}

const contractEntrustStopUpdateUrl = '/strategy/contractEntrustStop/update'
const contractEntrustStopUpdate = async (body = {}) => {
  const { data } = await axios.post(contractEntrustStopUpdateUrl, body)
  return data
}
const needLoginUrl = [contractEntrustStopUrl, contractPositionUrl, contractUsdtlistUrl, contractStatusUrl, transactionHistoryUrl, historyUrl, positionHistoryUrl, tradeHistoryUrl, openOrdersUrl, leverInfoUrl, positionDepositUrl]

export default {
  needLoginUrl,
  openOrders,
  history,
  getUserSettings,
  setUserSettings,
  historyDetail,
  positionHistory,
  tradeHistory,
  transactionHistory,
  markedPrice,
  cancelOrder,
  cancelOrderAll,
  // contractRate,
  contractPosition,
  contractUsdtlist,
  contractUsdtlist2,
  contractStatus,
  edditContractStatus,
  contractAddOrder,
  contractCoinmarket,
  leverInfo,
  rateHistory,
  updateLeverInfo,
  updateMargin,
  fee,
  feeList,
  curFee,
  currentRate,
  profitLossCreateOrder,
  contractEntrustStop,
  contractEntrustStopPage,
  contractEntrustStopSave,
  contractEntrustStopCancel,
  positionDeposit,
  contractEntrustStopUpdate
}
