const intervals = [
  {
    name: '1m',
    value: 'one_minute',
    interval: '60',
  },
  {
    name: '5m',
    value: 'five_minute',
    interval: '60',
  },
  {
    name: '15m',
    value: 'fifteen_minute',
    interval: '60',
  },
  {
    name: '30m',
    value: 'thirty_minute',
    interval: '60',
  },
  {
    name: '1H',
    value: 'one_hour',
    interval: '60',
  },
  {
    name: '4H',
    value: 'four_hour',
    interval: '1H',
  },
  {
    name: '1D',
    value: 'one_day',
    interval: '1D',
  },
  {
    name: '1W',
    value: 'one_week',
    interval: '1D',
  },
  {
    name: '1M',
    value: 'one_month',
    interval: '1D',
  },
]

const filterData = (cb, symbol, interval) => {
  return ({ data }) => {
    if (symbol == data?.coinMarket) {
      const klines = data.klines
      cb(klines[interval])
    }
  }
}

const chainOff = (re) => {
  const ws = FuturesSocket.autoConnect()

  for (let i = 0; i < ws?.eventsKeys?.length; i++) {
    const eKey = ws?.eventsKeys[i]
    if (re.test(eKey)) {
      ws.off(eKey)
    }
  }
}
const useFuturesStore = defineStore('futuresStore', {
  state: () => {
    // 方向类型，1：双向持仓；2：单向持仓
    const positionModeMap = {
      'hedge': 1,
      'oneWay': 2,
    }
    return {
      // theme: localStorage.theme ?? 'light', // light dark
      intervalName: localStorage.futuresKLineIntervalName || intervals[1].name,
      symbol: localStorage.fsymbol ?? '',
      kLineCbs: {},
      positionList: [],
      openOrders: [],
      openOrdersEntrustStop: [],
      openOrdersForm: { orderType: 1 },
      usdtTradeStatus: '', // 用户usdt永续合约交易状态,0-失效,1-有效
      positionMode: positionModeMap.hedge, // 仓位模式
      positionModeMap: positionModeMap,
      uniAssets: {}, // 单向持仓的
      sells: [],
      buys: [],
      symbolInfoDatas: {},
      curCoinMarket: {}, // 当前币种信息 包含面值
    }
  },

  getters: {
    positionModeOptions: (state) => [
      { label: $t('futures.positionModeMap.hedge'), value: state.positionModeMap.hedge },
      { label: $t('futures.positionModeMap.oneWay'), value: state.positionModeMap.oneWay },
    ],
    themeOptions: () => [
      { label: 'dark', value: 'dark' },
      { label: 'light', value: 'light' },
    ],
    statusOptions: () => [
      { label: $t('futures.statusMap.0'), value: 0 },
      { label: $t('futures.statusMap.1'), value: 1 },
      { label: $t('futures.statusMap.2'), value: 2 },
      { label: $t('futures.statusMap.3'), value: 3 },
      { label: $t('futures.statusMap.4'), value: 4 },
    ],
    positionTypeOptions: () => [
      { label: $t('futures.positionTypeMap.0'), value: 0 },
      { label: $t('futures.positionTypeMap.1'), value: 1 },
    ],
    orderFormOptions: () => [
      { label: 'Post Only', value: 3 },
      { label: 'IOC', value: 4 },
      { label: 'FOK', value: 5 },
    ],
    priceTypeOptions: () => [
      { label: $t('futures.limit'), value: 1 },
      { label: $t('futures.market'), value: 2 },
      { label: $t('futures.limittaker'), value: 3 },
      { label: $t('futures.markettaker'), value: 4 },
      { label: $t('futures.forcedPositionReduction'), value: 5 },
      { label: $t('futures.liquidation'), value: 6 },
      { label: $t('futures.adl'), value: 7 },
      { label: $t('futures.highLevelLimit'), value: 8 },
    ],
    priceTypeOptions2: () => [
      { label: $t('futures.limit'), value: 1 },
      { label: $t('futures.market'), value: 2 },
      { label: $t('futures.forcedPositionReduction'), value: 5 },
      { label: $t('futures.liquidation'), value: 6 },
      { label: $t('futures.adl'), value: 7 },
      { label: $t('futures.highLevelLimit'), value: 8 },
    ],
    closingTypeOptions: () => [
      { label: $t('futures.openTypeMap.1'), value: 1 },
      { label: $t('futures.openTypeMap.2'), value: 2 },
      { label: $t('futures.openTypeMap.3'), value: 3 },
      { label: $t('futures.openTypeMap.4'), value: 4 },
    ],
    closingTypeOptions2: () => [
      { label: $t('futures.openTypeMap2.1'), value: 1 },
      { label: $t('futures.openTypeMap2.2'), value: 2 },
    ],
    transactionTypeOptions: () => [
      { label: $t('futures.transactionTypeMap.1'), value: 1 },
      { label: $t('futures.transactionTypeMap.2'), value: 2 },
      { label: $t('futures.transactionTypeMap.3'), value: 3 },
      { label: $t('futures.transactionTypeMap.4'), value: 4 },
      { label: $t('futures.transactionTypeMap.5'), value: 5 },
      { label: $t('futures.transactionTypeMap.6'), value: 6 },
    ],
    triggerTypeOptions: () => [
      { label: $t('futures.mark'), value: 1 },
      { label: $t('futures.last'), value: 2 },
      { label: $t('futures.limit2'), value: 3 },
      { label: $t('futures.market2'), value: 4 },
    ],
    triggerDirectionOptions: () => [
      { label: $t('futures.takeProfitPrice2'), value: 1 },
      { label: $t('futures.stopLossPrice2'), value: 2 },
    ],
    mathMap: () => ({
      '1-1': '≥',
      '1-2': '≤',
      '2-2': '≥',
      '2-1': '≤',
    }),
    referenceOptions: () => [
      {
        label: $t('futures.mark'),
        labelFull: $t('futures.markPrice'),
        key: 1
      },
      {
        label: $t('futures.last'),
        labelFull: $t('futures.lastPrice'),
        key: 2
      }
    ],
    intervals: () => intervals,
    interval: (state) => intervals.find(e => e.name == state.intervalName),

  },
  actions: {

    async setFuturesSettings (v) {
      const data = await futuresApi.setUserSettings({ directionType: v })
      this.$patch(state => {
        state.positionMode = +v
      })
    },
    async getFuturesSettings () {
      const data = await futuresApi.getUserSettings({})
      // console.log('__getFuturesSettings__', data)
      this.$patch(state => {
        state.positionMode = data.directionType
      })
    },
    async getFuturesAccountInfo () {
      const data = await futuresApi.contractUsdtlist()
      this.$patch(state => {
        state._futuresAccountInfo = data ?? {}
      })
    },
    // -- KLine --
    async subKLine (symbol, intervalName, cb) {
      const ws = FuturesSocket.autoConnect()

      this.kLineCbs[symbol] = cb
      chainOff(new RegExp(`${WS_CONTRACT_KEYS.ticker}@KLine`))
      const key = `${WS_CONTRACT_KEYS.ticker}@KLine-${symbol}`
      const intervalInfo = intervals.find(e => e.name == intervalName)
      const ecb = filterData(cb, symbol, intervalInfo.value)

      ws.on(key, ecb, false)
    },
    async switchKLine (symbol, intervalName, cb) {
      const re = new RegExp(`${WS_CONTRACT_KEYS.ticker}@KLine`)
      this.kLineCbs = {}
      this.subKLine(symbol, intervalName, cb)
    },
    async unsubKLineEvent (symbol) {
      const ws = FuturesSocket.autoConnect()

      const key = `${WS_CONTRACT_KEYS.ticker}@KLine-${symbol}`

      ws.off(key)
    },
    async switchInterval (symbol, intervalName) {
      this.intervalName = intervalName
      this.unsubKLineEvent(symbol, false)
      this.subKLine(symbol, intervalName, this.kLineCbs[symbol])
    },

    // -- Price --
    async subPrice (symbol, cb) {
      const ws = FuturesSocket.autoConnect()

      const key = `${WS_CONTRACT_KEYS.ticker}@price-${symbol}`
      const ecb = ({ data }) => {
        if (symbol == data?.coinMarket) {
          cb(data)
        }
      }

      ws.on(key, ecb, false)
    },
    async unsubPrice (symbol) {
      const ws = FuturesSocket.autoConnect()

      const key = `${WS_CONTRACT_KEYS.ticker}@price-${symbol}`

      ws.off(key)
    },
    async switchPrice (symbol, cb) {
      const re = new RegExp(`${WS_CONTRACT_KEYS.ticker}@price`)
      chainOff(re)
      this.subPrice(symbol, cb)
    },

    // -- TradeHistory --
    async subTradeHistory (symbol, cb) {
      const ws = FuturesSocket.autoConnect()

      const key = `${WS_CONTRACT_KEYS.ticker}@tradeHistory-${symbol}`
      const ecb = ({ data }) => {
        if (symbol == data?.coinMarket) {
          cb(data.trade)
        }
      }

      ws.on(key, ecb, false)
    },
    async unsubTradeHistory (symbol) {
      const ws = FuturesSocket.autoConnect()
      const key = `${WS_CONTRACT_KEYS.ticker}@tradeHistory-${symbol}`
      ws.off(key)
    },
    async switchTradeHistory (symbol, cb) {
      const re = new RegExp(`${WS_CONTRACT_KEYS.ticker}@tradeHistory`)
      chainOff(re)
      this.subTradeHistory(symbol, cb)
    },

    // -- Depth --
    async subDepth (symbol, cb) {
      const ws = FuturesSocket.autoConnect()

      const eventName = `alpha-market-depth-contract-${symbol.toLowerCase()}-trade`
      const sendData = {
        'id': randomString(),
        'cmd': 'sub',
        'topic': eventName,
        'data': {}
      }
      ws.on(sendData.topic, ({ data }) => cb(data), false)
      ws.send(sendData)
    },
    unsubDepth (symbol) {
      const ws = FuturesSocket.autoConnect()
      const eventName = `alpha-market-depth-${symbol.replace(/\//, '-').toLowerCase()}-trade`
      if (!ws.hasEvent(eventName)) {
        return
      }
      const sendData = {
        'cmd': 'unsub',
        'topic': eventName,
        'data': {}
      }
      ws.off(sendData.topic)
      ws.send(sendData)
    },
    async switchDepth (symbol, cb) {
      const ws = FuturesSocket.autoConnect()
      const re = /^alpha-market-depth-(.+)-trade$/
      const eventName = `alpha-market-depth-${symbol.replace(/\//, '-').toLowerCase()}-trade`
      const previousEventName = ws?.eventsKeys.find(e => re.test(e))
      if (previousEventName == eventName) {
        return
      }
      if (!isEmpty(previousEventName)) {
        const sendData = {
          'cmd': 'unsub',
          'topic': previousEventName,
          'data': {}
        }
        ws.off(sendData.topic)
        ws.send(sendData)
      }
      chainOff(re)
      this.subDepth(symbol, cb)
    },

    // -- DepthPaint --
    async subDepthPaint (symbol, cb) {
      const ws = FuturesSocket.autoConnect()
      const eventName = `alpha-market-depth-contract-${symbol.toLowerCase()}-paint`
      const sendData = {
        'id': randomString(),
        'cmd': 'sub',
        'topic': eventName,
        'data': {}
      }

      ws.on(sendData.topic, ({ data }) => cb(data), false)
      ws.send(sendData)
    },
    unsubDepthPaint (symbol) {
      const ws = FuturesSocket.autoConnect()
      const eventName = `alpha-market-depth-contract-${symbol.toLowerCase()}-paint`
      if (!ws.hasEvent(eventName)) {
        return
      }
      const sendData = {
        'cmd': 'unsub',
        'topic': eventName,
        'data': {}
      }

      ws.off(sendData.topic)
      ws.send(sendData)
    },
    async switchDepthPaint (symbol, cb) {
      try {
        const ws = FuturesSocket.autoConnect()
        const re = /^alpha-market-depth-(.+)-paint$/
        const eventName = `alpha-market-depth-contract-${symbol.toLowerCase()}-paint`
        const previousEventName = ws?.eventsKeys.find(e => re.test(e))
        if (previousEventName == eventName) {
          return
        }
        if (!isEmpty(previousEventName)) {
          const sendData = {
            'cmd': 'unsub',
            'topic': previousEventName,
            'data': {}
          }
          ws.off(sendData.topic)
          ws.send(sendData)
        }
        chainOff(re)
        this.subDepthPaint(symbol, cb)
      } catch (e) {
        console.log('switchDepthPaint', e)
      }
    },

    // --MarketPrice--
    async subMarketPrice (symbol, cb) {
      const ws = FuturesSocket.autoConnect()
      const eventName = `usdt-marked-price-${symbol.replace(/\//, '').toLowerCase()}`
      const sendData = {
        'id': randomString(),
        'cmd': 'sub',
        'topic': eventName,
        'data': {}
      }
      ws.on(sendData.topic, ({ data }) => cb(data), false)
      ws.send(sendData)
    },
    unsubMarketPrice (symbol) {
      const ws = FuturesSocket.autoConnect()
      const eventName = `usdt-marked-price-${symbol.replace(/\//, '').toLowerCase()}`
      if (!ws.hasEvent(eventName)) return
      const sendData = {
        'cmd': 'unsub',
        'topic': eventName,
        'data': {}
      }

      ws.off(sendData.topic)
      ws.send(sendData)
    },
    switchMarketPrice (symbol, cb) {
      try {
        const ws = FuturesSocket.autoConnect()
        const re = /^sdt-marked-price-(.+)$/
        const eventName = `usdt-marked-price-${symbol.replace(/\//, '').toLowerCase()}`
        const previousEventName = ws?.eventsKeys.find(e => re.test(e))
        if (previousEventName == eventName) {
          return
        }
        if (!isEmpty(previousEventName)) {
          const sendData = {
            'cmd': 'unsub',
            'topic': previousEventName,
            'data': {}
          }
          ws.off(sendData.topic)
          ws.send(sendData)
        }
        chainOff(re)
        this.subDepthPaint(symbol, cb)
      } catch (e) {
        console.log('switchMarketPrice', e)
      }
    },
    async getPosition () {
      const body = {
        // coinMarket: this.symbol
      }
      const data = await futuresApi.contractPosition(body)
      this.$patch(state => {
        state.positionList = data
      })
    },
    unsubPosition () {
      const userStore = useUserStore()
      if (!userStore.token) {
        return
      }
      const ws = FuturesSocket.autoConnect()
      const topic = WS_CONTRACT_KEYS.position
      const sendData = {
        'cmd': 'unsub',
        'topic': topic,
        'data': {}
      }
      ws.off(topic)
      ws.send(sendData)
    },
    subPosition () {
      const userStore = useUserStore()
      if (!userStore.token) {
        return
      }
      const ws = FuturesSocket.autoConnect()
      const topic = WS_CONTRACT_KEYS.position
      const sendData = {
        'id': randomString(),
        'cmd': 'sub',
        'topic': topic,
        'data': {}
      }

      ws.on(sendData.topic, ({ data = {}}) => {
        if (isEmpty(data)) {
          return
        }
        this.$patch(state => {
          const index = state.positionList.findIndex(e => e.id == data.id)
          if (data.status == 0 && index != -1) {
            state.positionList.splice(index, 1)
            eventBus.emit('position-change')
          } else if (data.status == 1 && index != -1) {
            if (state.positionList[index].flatAmount != data.flatAmount) {
              eventBus.emit('position-override', data)
              eventBus.emit('position-change')
            }
            state.positionList[index] = data
          } else if (data.status == 1 && index == -1) {
            state.positionList.push(data)
            eventBus.emit('position-change')
          }
        })
      })
      ws.send(sendData)
    },
    unsubOpenOrders  () {
      const userStore = useUserStore()
      if (!userStore.token) {
        return
      }
      const ws = FuturesSocket.autoConnect()
      const topic = WS_CONTRACT_KEYS.openOrders
      const sendData = {
        'cmd': 'unsub',
        'topic': topic,
        'data': {}
      }
      ws.off(topic)
      ws.send(sendData)
    },
    subOpenOrders  () {
      const userStore = useUserStore()
      if (!userStore.token) {
        return
      }
      const ws = FuturesSocket.autoConnect()
      const topic = WS_CONTRACT_KEYS.openOrders
      const sendData = {
        'id': randomString(),
        'cmd': 'sub',
        'topic': topic,
        'data': {}
      }

      ws.on(sendData.topic, ({ data = {}}) => {
        if (isEmpty(data)) {
          return
        }
        eventBus.emit(WS_CONTRACT_KEYS.openOrders, data)
      })
      ws.send(sendData)
    },
    unsubStop  () {
      const userStore = useUserStore()
      if (!userStore.token) {
        return
      }
      const ws = FuturesSocket.autoConnect()
      const topic = WS_CONTRACT_KEYS.stop
      const sendData = {
        'cmd': 'unsub',
        'topic': topic,
        'data': {}
      }
      ws.off(topic)
      ws.send(sendData)
    },
    subStop  () {
      const userStore = useUserStore()
      if (!userStore.token) {
        return
      }
      const ws = FuturesSocket.autoConnect()
      const topic = WS_CONTRACT_KEYS.stop
      const sendData = {
        'id': randomString(),
        'cmd': 'sub',
        'topic': topic,
        'data': {}
      }

      ws.on(sendData.topic, ({ data = {}}) => {
        if (isEmpty(data)) {
          return
        }
        eventBus.emit(WS_CONTRACT_KEYS.stop, data)
      })
      ws.send(sendData)
    },
    runSocket () {
    },
    async getContractStatus () {
      try {
        const status = await futuresApi.contractStatus()
        this.$patch(state => {
          state.usdtTradeStatus = status.usdtTradeStatus
        })
      } catch (error) {
        console.log('获取合约状态失败', error)
      } finally {
      }
    },
    async getPositionDeposit () {
      const body = {
        coinMarket: this.symbol
      }
      const data = await futuresApi.positionDeposit(body)
      this.$patch(state => {
        state.uniAssets = data.data
      })
    }
  },
  init () {
    this.runSocket()
    this.getContractStatus()

    this.$subscribe((mutation, state) => {
      localStorage.futuresKLineIntervalName = state.intervalName
      localStorage.fsymbol = state.symbol
    })
  },
})

export default useFuturesStore
