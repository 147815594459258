/*
 * @Author: ken yang
 * @Date: 2022-08-30 11:14:14
 * @LastEditTime: 2023-05-31 18:00:08
 * @LastEditors: ken yang
 * @Description:
 * @FilePath: /uexch_pc/src/store/config.js
 */
import { defineStore } from 'pinia'

const useConfigStore = defineStore('configStore', {
  state: () => {
    const winLocale = getUrlQuery().locale
    const browserLang = borwserLang()
    const tradeStyleMap = {
      'trade-style-0': 'trade-style-0',
      'trade-style-1': 'trade-style-1',
    }
    const tradeStyleDefault = tradeStyleMap[Object.keys(tradeStyleMap)[0]]
    const tradeStyleKeys = Object.keys(tradeStyleMap)
    const tradeStyle = tradeStyleKeys.find(e => e == localStorage.tradeStyle) ? localStorage.tradeStyle : tradeStyleDefault
    localStorage.tradeStyle = tradeStyle
    return {
      theme: localStorage.theme ?? 'light', // light dark
      locale: winLocale || localStorage.getItem('locale') || browserLang,
      tradeStyleMap: tradeStyleMap,
      tradeStyle: tradeStyle,
      tradeStyleDefault: tradeStyleDefault,
      languageMap: [
        {
          label: '简体中文',
          value: LANGCN,
        },
        {
          label: 'English',
          value: LANGEN,
        },
      ]
    }
  },
  getters: {
    languageName: (state) => state.languageMap.find(item => item.value == state.locale)?.label,
    tradeStyleIsDefault: (state) => state.tradeStyle == state.tradeStyleDefault
  },
  actions: {
    async setLanguage (value) {
      this.locale = value
      $t.changeLange(value)
    },
    async setTradeStyle (value) {
      const tradeStyleMap = {
        'trade-style-0': 0,
        'trade-style-1': 1,
      }
      this.tradeStyle = value
      localStorage.tradeStyle = value
      configApi.switchTheme({ theme: tradeStyleMap[value] })
    },
    async tradeStyleSwitch () {
      let v
      if (this.tradeStyle == 'trade-style-0') {
        v = 1
      } else if (this.tradeStyle == 'trade-style-1') {
        v = 0
      }
      this.tradeStyle = `trade-style-${v}`
      configApi.switchTheme({ theme: v })
    }
  },
  init () {
    const HTMLDom = document.querySelector('html')
    HTMLDom.classList.add(`app-language-${this.locale}`)
    this.$subscribe((mutation, state) => {
      localStorage.theme = state.theme
      localStorage.tradeStyle = state.tradeStyle
      localStorage.locale = state.locale
    })
  },
})

export default useConfigStore
