/*
 * @Author: ken yang
 * @Date: 2022-09-27 17:49:48
 * @LastEditTime: 2022-11-28 10:51:45
 * @LastEditors: ken yang
 * @Description: 返佣接口
 * @FilePath: /uexch_pc/src/api/rebate.js
 */
import axios from '@/utils/request'

// 获取返佣信息

const inviteInfoUrl = '/user/invite/info'
const inviteInfo = async (params) => {
  const { data } = await axios.post(inviteInfoUrl, params)
  return data.data
}

// 我的邀请
const userInfoUrl = '/activity/user/info'
const userInfo = async (params) => {
  const { data } = await axios.post(userInfoUrl, params)
  return data.data
}

// 返佣记录/邀请记录
const commissionUserRecordUrl = '/activity/user/rakeBackRecord'
const commissionUserRecord = async (params = {}) => {
  const { data } = await axios.post(commissionUserRecordUrl, params)
  return data.data
}

// 个人返佣记录 - 用户
const rakeBackPersonRecordUrl = '/user/rakeBackPersonRecord/info'
const rakeBackPersonRecord = async (params) => {
  const { data } = await axios.post(rakeBackPersonRecordUrl, params)
  return data.data
}

// 个人返佣记录 - 币种列表
const coinsUrl = '/activity/user/rakeBackCoin'
const coins = async (params) => {
  const { data } = await axios.post(coinsUrl, params)
  return data.data
}

const needLoginUrl = [inviteInfoUrl]

export default {
  needLoginUrl,
  inviteInfo,
  userInfo,
  commissionUserRecord,
  rakeBackPersonRecord,
  coins,
}
