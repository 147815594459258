const checkType = v => Object.prototype.toString.call(v)
const isArray = v => checkType(v) == '[object Array]'
const isString = v => checkType(v) == '[object String]'
const isNumber = v => checkType(v) == '[object Number]'
const isObject = v => checkType(v) == '[object Object]'
const isBoolean = v => checkType(v) == '[object Boolean]'
const isFunc = v => checkType(v) == '[object Function]'
const isRe = v => checkType(v) == '[object RegExp]'
const isNaN = v => v.toString() == 'NaN'
const isZero = (v) => {
  const str = String(v)
  return str.split('').every(e => e == 0)
}
const isJsonString = str => {
  if (typeof str == 'string') {
    try {
      JSON.parse(str)
      return true
    } catch (e) {
      return false
    }
  }
}
const isEmpty = v => {
  if (v === undefined || v === '') {
    return true
  }
  const list = ['{}', '[]', 'null', 'undefined']
  return list.includes(JSON.stringify(v))
}

const hasEmpty = (...age) => {
  return age.some(v => isEmpty(v))
}

export {
  checkType,
  isArray,
  isString,
  isNumber,
  isObject,
  isBoolean,
  isFunc,
  isRe,
  isJsonString,
  isEmpty,
  hasEmpty,
  isNaN,
  isZero,
}
